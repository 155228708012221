// post请求发送paras返回json
const post_data = (url, paras = {}) => {
    return fetch(url, {
        method: 'post',
        // credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(paras),
    }).then(res => res.json()).catch(err => ({ status: false, err }))
}

// 为数组data添加索引
const add_index_for_fun = arr => {
    arr.map((item, index) => {
        item.index = index
        item.process_status_button = false
    })
    return arr
}

// 格式化路由数据
const format_router = data => {
    const router = []
    const circle = data => {
        return data.map(item => {
            if (item.children) {
                circle(item.children)
            } else {
                router.push({ route: item.route, component: item.component, para: item.para ? item.para : '' })
            }
        })
    }
    circle(data)
    return router
}

//删除指定id的数据列表
const delete_by_id = (data, id) => {
    const res = []
    data.map(item => {
        if (item.id != id) {
            res.push(item)
        }
    })
    return res
}
//删除指定ids的数据列表
const delete_by_ids = (data, ids) => {
    const res = []
    data.map(item => {
        let flag = true
        ids.map(item2 => {
            if (item.id == item2) {
                flag = false
            }
        })
        if (flag) {
            res.push(item)
        }
    })
    return res
}

// 修改列表指定id为新值
const alter_arr_value_by_id = (data, id, obj) => {
    // console.log(obj)
    data.map(item => {
        if (item.id == id) {
            for (let i in obj) {
                item[i] = obj[i]
            }
        }
    })
    return data
}

// 得到y-m-d日期
const formatDate = now => {
    let y = ''
    let m = ''
    let d = ''
    y = now.getFullYear();
    m = now.getMonth() + 1;
    d = now.getDate();
    return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}

// 过滤得到日期的月份筛选
const filter_month_arr = (data, col) => {
    const date_arr = []
    data.map(item => {
        if (date_arr.indexOf(item[col].slice(0, 7)) === -1) {
            date_arr.push(item[col].slice(0, 7))
        }
    })
    const date_arr_res = date_arr.sort()
    const res = []
    date_arr_res.map(item => {
        res.push({ text: item, value: item })
    })
    return res
}

// 检测特殊字符串
const filter_string = str => {
    const arr = ["'", '"', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',]
    let str_new = String(str)
    for (let i = 0; i < str_new.length; i++) {
        if (str_new[i] == ' ') {
            return true
        }
        for (let j = 0; j < arr.length; j++) {
            if (str_new[i] === arr[j]) {
                return true
            }
        }
    }
    return false
}

// 去除特殊字符串
const delete_string = str => {
    str = str.replace(/[\r\n]/g, "");
    return str.replace(/[\^\-\|\~\`\(\)\#\$\^\*\{\}\"\']/g, '')
}
// 去除特单引号殊字符串
const delete_only_string = str => {
    return str.replace(/[\^\'\<\>\?]/g, '')
}
// 提取对象数组中的字段
const get_arr_by_arr_obj = (data, key) => {
    const res = []
    data.map(item => {
        res.push(item[key])
    })
    return res
}

export default {
    get_arr_by_arr_obj,
    filter_month_arr,
    post_data,
    format_router,
    add_index_for_fun,
    delete_by_id,
    delete_by_ids,
    alter_arr_value_by_id,
    formatDate,
    filter_string,
    delete_string,
    delete_only_string,
}