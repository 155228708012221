// 路由核心文件 
export default [
    {
        title: '联系我们管理',
        route: '/contact_us',
        component: 'CantactUs',
    },
    {
        title: '我要提问管理',
        route: '/ask_question',
        children: [
            {
                title: '问题类目管理',
                route: '/ask_question/category',
                component: 'AskQCategory',
            },
            {
                title: '问题查看回复',
                route: '/ask_question/check_reply',
                component: 'AskQCheckReply',
            }
        ]
    },
    {
        title: '企业服务管理',
        route: '/co_service',
        children: [
            {
                title: '服务类目管理',
                route: '/co_service/category',
                component: 'CoServiceCategory',
            },
            {
                title: '服务文章管理',
                route: '/co_service/article',
                component: 'CoServiceArticle',
            }
        ]
    },
    {
        title: '文章管理',
        route: '/article',
        children: [
            {
                title: '全部文章',
                route: '/article/all_article',
                component: 'AllArticle',
            },
            {
                title: '关于我们',
                route: '/article/about_us',
                component: 'ArticleAboutUs',
            },
            {
                title: '新闻要点',
                route: '/article/news',
                component: 'ArticleNews',
            },
            {
                title: '轮播图文章',
                route: '/article/banner',
                component: 'ArticleBanner',
            },
            {
                title: '政策宣传',
                route: '/article/policy',
                component: 'ArticlePolicy',
            },
            {
                title: '园区资讯',
                route: '/article/park_news',
                component: 'ArticleParkNews',
            },
            {
                title: '园区公告',
                route: '/article/park_notice',
                component: 'ArticleParkNotice',
            },
            {
                title: '园区招商',
                route: '/article/park_investment',
                component: 'ArticleParkInvestment',
            },
            {
                title: '通知公告',
                route: '/article/notice',
                component: 'ArticleNotice',
            },
            {
                title: '常见问题',
                route: '/article/common_question',
                component: 'ArticleCommonQuestion',
            },
        ]
    },
    {
        title: '企业注册类目管理',
        route: '/co_reg_selection',
        component: 'CoRegSelection',
    },
    {
        title: '注册会员管理',
        route: '/member',
        component: 'Member',
    },
    {
        title: '私密消息管理',
        route: '/personal_message',
        component: 'PersonalMessage',
    },
    {
        title: '问卷调查管理',
        route: '/questionnaire',
        children: [
            {
                title: '发布问卷',
                route: '/questionnaire/release',
                component: 'QuestionnaireRelease',
            },
            {
                title: '查看问卷',
                route: '/questionnaire/check',
                component: 'QuestionnaireCheck',
            }
        ]
    },
    {
        title: '服务职责管理',
        route: '/service_duty',
        component: 'ServiceDuty',
    },
    {
        title: 'UDA管理',
        route: '/uda',
        component: 'Uda',
    },
    {
        title: '限制用户次数',
        route: '/limit_user_times',
        component: 'LimitUserTimes',
    },
    {
        title: '修改密码',
        route: '/alter_pwd',
        component: 'AlterPwd',
    },
    {
        title: '管理员管理',
        route: '/manage_manager',
        component: 'ManageManager',
    },
    {
        title: '后台配置',
        route: '/basic_config',
        component: 'BasicConfig',
    },
]