import React from 'react'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import router_menu from './router_menu'
import common_fun from './common_fun'
import NoFound from '../pages/no_found/index'

// 配置路由的自动加载
export default class RouterConfig extends React.Component {
    renderRoutes = routeConfig => routeConfig.map(item => {
        const LoadableComponent = loadable(() => import(`../pages${item.route}/index.js`))
        const route = item.para ? item.route+'/:'+item.para : item.route
        return <Route key={item.route} path={route} component={props => <LoadableComponent {...props} />} />
    })
    
    render() {
        return (
            <Switch>
                {this.renderRoutes(common_fun['format_router'](router_menu))}
                <Route key='no_found' path='*' component={NoFound} />
            </Switch>
        )
    }
}
