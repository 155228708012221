import React from 'react'
import common_fun from '../../common/common_fun'
import { Menu, Icon } from 'antd';
const { SubMenu } = Menu;

export default class MenuLeft extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      router_menu: [],
    }
  }

  componentDidMount() {
    this.setState({
      router_menu: this.props.data
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      router_menu: nextProps.data
    })
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onHandleClick = e => {
    this.props.menuSelect(e.route)
  }

  renderMenuList = data => {
    return data.map(v => {
      if (!v.menu_hidden) {
        return v.children ? <SubMenu key={v.route} title={<span><Icon style={{fontSize: 15}} type={v.icon} /><span>{v.title}</span></span>}>{this.renderMenuList(v.children)}</SubMenu> : <Menu.Item key={v.route}><a href={'/#' + v.route}><Icon style={{fontSize: 15}} type={v.icon} />{v.title}</a></Menu.Item>
      }
    })
  }

  render() {
    return (
      <div>
        <Menu
          onSelect={this.onHandleClick}
          mode="inline"
          theme="light"
          inlineCollapsed={this.state.collapsed}
        >
          <Menu.Item key='index'><a href={'/#'}><Icon type={'home'} />回到首页</a></Menu.Item>
          {this.renderMenuList(this.state.router_menu)}
        </Menu>
      </div>
    )
  }
}