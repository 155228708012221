// 首页信息，404错误页

import React from 'react'
import { Card, Col, Row, Statistic, Button, Icon } from 'antd'
import fun from './../../common/common_fun'
import url from './../../common/url_config'
import './../../styles/common.css'
import { func } from 'prop-types'
import { Link } from 'react-router-dom'
import ReactEcharts from 'echarts-for-react'

export default class NoFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      current_select: 'week',
      data: {},
    }
  }

  componentDidMount() {
    fun['post_data'](url['index_data'], {}).then(res => {
      console.log(res)
      this.setState({
        res,
        data: res.data_basic,
        members: res.members,
        questionnaire: res.questionnaire,
        ask_question: res.ask_question,
      })
      this.setState({ loading: false })
    })
  }

  render_simple_option = (data, type, obj) => ({
    ...obj,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: {
      type: 'value'
    },
    xAxis: {
      type: 'category',
      data: data[type]['date'],
    },
    series: [{ data: data[type]['data'], type: 'bar' }]
  })

  render_bar_option = (data, type) => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    legend: {
      data: data[type]['name'],
    },
    yAxis: {
      type: 'value'
    },
    xAxis: {
      type: 'category',
      data: data[type]['date'],
    },
    series: data[type].data.map((item, index) => ({
      name: data[type]['name'][index],
      type: 'bar',
      stack: '总量',
      label: {
        show: true,
        position: 'insideRight'
      },
      data: item
    }))
  })

  render_pie_option = (data, key_obj) => ({
    title: {
      text: key_obj.name,
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: fun['get_arr_by_arr_obj'](data[key_obj.key], 'name')
    },
    series: [
      {
        name: key_obj.name,
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: data[key_obj.key],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  })

  render() {
    const select_arr = [
      {
        key: 'week',
        name: '近一周内',
      },
      {
        key: 'month',
        name: '近一个月内',
      },
      {
        key: 'year',
        name: '近一年内',
      },
    ]
    const members_select_arr = [
      {
        key: 'country',
        name: '国家',
      },
      {
        key: 'co_type',
        name: '企业类型',
      },
      {
        key: 'industry',
        name: '行业',
      },
    ]

    return (
      <div style={{ width: '99%' }}>
        <h1 style={{ textAlign: 'center', marginTop: 15, color: '#1ea0fa' }}><Icon type="smile" /> 欢迎使用React & AntDesign后台管理系统</h1>
        <div style={{ height: 140 }}>
          <Card style={{ width: '49.5%', float: 'left' }}>
            <p><Icon style={{ fontSize: 16 }} type='phone' /> 电话：{this.state.data.tel || ''}</p>
            <p><Icon style={{ fontSize: 16 }} type='qq' /> qq：{this.state.data.qq || ''}</p>
          </Card>
          <Card style={{ width: '49.5%', float: 'right' }}>
            <p><Icon style={{ fontSize: 16 }} type='mail' /> 电邮：{this.state.data.email || ''}</p>
            <p><Icon style={{ fontSize: 16 }} type='wechat' /> 微信：{this.state.data.wechat || ''}</p>
          </Card>
        </div>
        <div>
          <h1 style={{ textAlign: 'center', color: '#1ea0fa', }}><Icon type="bar-chart" /> 数据展示</h1>
          {select_arr.map(item => <Button disabled={this.state.loading} key={item.key} style={{ margin: 10 }} type={this.state.current_select === item.key ? 'primary' : 'default'} onClick={() => {
            this.setState({
              current_select: item.key,
              loading: true,
            })
            const t1 = setTimeout(() => {
              this.setState({
                loading: false,
              })
            }, 500)
          }}>显示{item.name}数据</Button>
          )}
          {!this.state.loading ? <div>
            <div style={{ marginTop: 20 }}>
              <h2 style={{ textIndent: 10, color: '#1ea0fa', textAlign: 'center' }}><a href='/#/ask_question/check_reply'><Icon type='question-circle' /> 我要提问数目</a></h2>
              <ReactEcharts option={this.render_bar_option(this.state.res.ask_question, this.state.current_select)} />
            </div>
            <div style={{ marginTop: 20 }}>
              <h2 style={{ textIndent: 10, color: '#1ea0fa', textAlign: 'center' }}><a href='/#/questionnaire/check'><Icon type='snippets' /> 问卷调查数目</a></h2>
              <ReactEcharts option={this.render_simple_option(this.state.res.questionnaire, this.state.current_select, { color: ['#1ea0fa'] })} />
            </div>
            <div style={{ marginTop: 20 }}>
              <h2 style={{ textIndent: 10, color: '#1ea0fa', textAlign: 'center' }}><a href='/#/member'><Icon type='user' /> 新增会员数目</a></h2>
              <ReactEcharts option={this.render_simple_option(this.state.res.members, this.state.current_select)} />
            </div>
            <div style={{ marginTop: 20 }}>
              <h2 style={{ textIndent: 10, color: '#1ea0fa', textAlign: 'center' }}><a href='/#/member'><Icon type='pie-chart' /> 所有会员信息分布情况</a></h2>
              {members_select_arr.map(item => <div style={{ width: 750, margin: '30px auto' }} key={item.key}><ReactEcharts option={this.render_pie_option(this.state.res.members, item)} /></div>)}
            </div>
          </div> : <p style={{ color: '#1ea0fa', textAlign: 'center', fontSize: 16, marginTop: 20 }}>内容加载中，请稍等... <Icon style={{ paddingLeft: 15 }} type='loading' /></p>}
        </div>
      </div>
    )
  }
}