// 接口url头部
// const url_head = 'http://106.13.34.154/'
const url_head = 'https://qiyeyuan.wxdaiyan.cn/'

export default {
    url_head,
    index_data: url_head + 'back_api/index.php', //获取首页信息
    get_emergency: url_head + 'back_api/get_basic_config/get_emergency.php', //获取网站头部紧急联系人电话

    get_basic_config: url_head + 'back_api/get_basic_config/get_basic_config.php', // 总管理员获取后台基本配置
    alter_basic_config: url_head + 'back_api/get_basic_config/alter_basic_config.php', // 总管理员修改后台基本配置

    upload_img: url_head + 'back_api/common/upload_img.php', // 管理员上传图片
    upload_file: url_head + 'back_api/common/upload_file.php', // 管理员上传文件
    test_login: url_head + 'back_api/login/test_login.php', //验证登录
    get_authority: url_head + 'back_api/login/get_authority.php', //管理员获取权限

    get_manage_manager: url_head + 'back_api/manage_manager/get_data.php', // 管理员管理获取数据
    super_alter_user_pwd: url_head + 'back_api/manage_manager/super_alter_user_pwd.php', // 总管理员修改普通管理员帐号密码
    super_alter_authority: url_head + 'back_api/manage_manager/super_alter_authority.php', // 总管理员修改权限
    super_delete_user: url_head + 'back_api/manage_manager/super_delete_user.php', //总管理员删除管理员
    super_add_user: url_head + 'back_api/manage_manager/super_add_user.php', // 总管理员新建管理员

    get_news_list: url_head + 'back_api/news/get_news_list.php',//获取新闻列表
    delete_news: url_head + 'back_api/news/delete_news.php',//删除新闻
    get_news_con: url_head + 'back_api/news/get_news_con.php',//获取新闻详情
    confirm_alter_news_con: url_head + 'back_api/news/confirm_alter_news_con.php',//修改新闻
    insert_news: url_head + 'back_api/news/insert_news.php',//添加新闻
    get_news_list_all: url_head + 'back_api/news/get_news_list_all.php',//总管理员获取所有新闻列表
    alter_index_bg: url_head + 'back_api/news/alter_index_bg.php',//更换首页新闻背景图

    alter_pwd: url_head + 'back_api/alter_pwd/alter_pwd.php',//管理员修改密码

    get_limit_user_times: url_head + 'back_api/get_limit_user_times/get_data.php',//获取限制用户提交表单次数
    alter_limit_user_times: url_head + 'back_api/get_limit_user_times/alter_data.php',//修改限制用户提交表单次数

    get_uda_introduce: url_head + 'back_api/uda/get_uda_introduce.php',//获取uda介绍
    alter_uda_introduce: url_head + 'back_api/uda/alter_uda_introduce.php',//修改uda介绍

    get_uda_class: url_head + 'back_api/uda/get_uda_class.php',//获取uda企业类型
    search_uda_class: url_head + 'back_api/uda/search_uda_class.php',//搜索企业所属的uda企业类型
    add_uda_class: url_head + 'back_api/uda/add_uda_class.php',//增加uda企业类型
    alter_uda_class: url_head + 'back_api/uda/alter_uda_class.php',//修改uda企业类型
    delete_uda_class: url_head + 'back_api/uda/delete_uda_class.php',//删除uda企业类型
    resort_uda_class: url_head + 'back_api/uda/resort_uda_class.php',//更新uda企业类型排序

    get_uda_list: url_head + 'back_api/uda/get_uda_list.php',//获取uda企业
    add_uda_list: url_head + 'back_api/uda/add_uda_list.php',//增加uda企业
    alter_uda_list: url_head + 'back_api/uda/alter_uda_list.php',//修改uda企业
    delete_uda_list: url_head + 'back_api/uda/delete_uda_list.php',//删除uda企业

    get_contact_us: url_head + 'back_api/contact_us/get_data.php', // 获取联系我们
    alter_contact_us: url_head + 'back_api/contact_us/alter_data.php', // 修改联系我们    
    add_contact_us: url_head + 'back_api/contact_us/add_data.php', // 增加联系我们    
    delete_contact_us: url_head + 'back_api/contact_us/delete_data.php', // 删除联系我们  
    resort_contact_us: url_head + 'back_api/contact_us/resort_data.php', // 更新联系我们排序

    get_ask_question_category: url_head + 'back_api/ask_question/get_category.php', // 获取我要提问类目
    add_ask_question_category: url_head + 'back_api/ask_question/add_category.php', // 添加我要提问类目
    delete_ask_question_category: url_head + 'back_api/ask_question/delete_category.php', // 删除我要提问类目
    resort_ask_question_category: url_head + 'back_api/ask_question/resort_category.php', // 更新我要提问类目排序

    get_ask_question_form: url_head + 'back_api/ask_question/get_ask_question_form.php', // 获取我要提问表单
    get_ask_question_reply: url_head + 'back_api/ask_question/get_ask_question_reply.php', // 获取我要提问回复
    delete_ask_question_reply: url_head + 'back_api/ask_question/delete_ask_question_reply.php', // 删除我要提问回复
    add_ask_question_reply: url_head + 'back_api/ask_question/add_ask_question_reply.php', // 添加我要提问回复

    get_co_service_category: url_head + 'back_api/co_service/get_category.php', // 获取企业服务类目
    add_co_service_category: url_head + 'back_api/co_service/add_category.php', // 添加企业服务类目
    delete_co_service_category: url_head + 'back_api/co_service/delete_category.php', // 删除企业服务类目 
    resort_co_service_category: url_head + 'back_api/co_service/resort_category.php', // 更新企业服务类目排序 

    get_co_service_list: url_head + 'back_api/co_service/get_co_service_list.php', // 获取企业服务文章列表
    delete_co_service_list: url_head + 'back_api/co_service/delete_co_service_list.php', // 删除企业服务文章
    alter_co_service_list: url_head + 'back_api/co_service/alter_co_service_list.php', // 修改企业服务文章
    add_co_service_list: url_head + 'back_api/co_service/add_co_service_list.php', // 添加企业服务文章

    get_co_reg_selection: url_head + 'back_api/co_reg_selection/get_data.php', // 获取企业注册类目
    add_co_reg_selection: url_head + 'back_api/co_reg_selection/add_data.php', // 添加企业注册类目
    delete_co_reg_selection: url_head + 'back_api/co_reg_selection/delete_data.php', // 删除企业注册类目  

    get_service_duty: url_head + 'back_api/service_duty/get_data.php', // 获取服务职责
    add_service_duty: url_head + 'back_api/service_duty/add_data.php', // 添加服务职责
    delete_service_duty: url_head + 'back_api/service_duty/delete_data.php', // 删除服务职责
    alter_service_duty: url_head + 'back_api/service_duty/alter_data.php', // 修改服务职责
    resort_service_duty: url_head + 'back_api/service_duty/resort_data.php', // 更新服务职责排序

    get_personal_message: url_head + 'back_api/personal_message/get_data.php', // 获取私人信息
    add_personal_message: url_head + 'back_api/personal_message/add_data.php', // 添加私人信息
    delete_personal_message: url_head + 'back_api/personal_message/delete_data.php', // 删除私人信息

    get_questionnaire: url_head + 'back_api/questionnaire/get_questionnaire_data.php', // 获取调查列表
    add_questionnaire: url_head + 'back_api/questionnaire/add_questionnaire_data.php', // 添加调查
    delete_questionnaire: url_head + 'back_api/questionnaire/delete_questionnaire_data.php', // 删除调查

    get_questionnaire_form: url_head + 'back_api/questionnaire/get_questionnaire_form_data.php', // 获取调查表单

    get_members: url_head + 'back_api/members/get_data.php', // 获取会员列表
    identify_members: url_head + 'back_api/members/identify.php', // 修改认证
    alter_delete_members: url_head + 'back_api/members/alter_delete.php', // 锁定与解锁会员
    delete_members: url_head + 'back_api/members/delete_data.php', // 彻底删除会员
}