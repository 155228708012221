// 登录页面

import React from 'react'
import Cookies from 'js-cookie'
import { message, Form, Icon, Input, Button, Checkbox } from 'antd'
import './../../styles/index.css'
import fun from './../../common/common_fun'
import url from './../../common/url_config'

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      pwd: '',
      is_loading: false
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.user == '' || this.state.pwd == '') {
      alert('请正确填写账号密码！')
      return
    }
    this.setState({
      is_loading: true
    })
    fun['post_data'](url['test_login'], this.state).then(res => {
      if (res.status) {
        message.success('登录成功')
        Cookies.set("random_str", res.random_str)
        Cookies.set("user", this.state.user)
        this.props.onClick({ user: this.state.user, random_str: res.random_str })
      } else {
        alert(res.err)
      }
      this.setState({
        is_loading: false
      })
    })
  }

  render() {
    return (
      <div className='login_root'>
        <h2>企业园后台登录</h2>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,0.25)' }} />}
              placeholder="用户名"
              value={this.state.user}
              onChange={e => this.setState({ user: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,0.25)' }} />}
              type="password"
              placeholder="密码"
              value={this.state.pwd}
              onChange={e => this.setState({ pwd: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">{this.state.is_loading ? '登录中...' : '登录'}</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}