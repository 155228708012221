import React from 'react'
import RouterConfig from './common/router_config'
import { HashRouter } from 'react-router-dom'
import './styles/index.css'
import 'antd/dist/antd.css'
import MenuLeft from './components/menu/index'
import Login from './components/login/index'
import { Button, Icon } from 'antd'
import Cookies from 'js-cookie'
import fun from './common/common_fun'
import url from './common/url_config'

const screen_height = document.body.clientHeight
const cssRoot = {
  height: screen_height - 45 + 'px',
  overflow: 'auto'
}

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: Cookies.get('user'),//用户名cookie
      random_str: Cookies.get('random_str'),//随即字符串cookie
      authority: [],//权限列表
      is_authority_ok: false,//权限是否获取成功
      emergency: '',//紧急联系我
    }
  }

  componentDidMount() {
    if (this.state.user) {
      this.get_authority(this.state.user, this.state.random_str)
    }
    const paras = {
      user: this.state.user,
      random_str: this.state.random_str,
    }
    fun['post_data'](url['get_emergency'], paras).then(res => {
      if (res.status) {
        this.setState({ emergency: res.data })
      }
    })
  }

  get_authority(user, random_str) {
    fun['post_data'](url['get_authority'], { user, random_str }).then(res => {
      console.log(res)
      if (res.status) {
        this.setState({
          authority: JSON.parse(res.authority),
          is_authority_ok: true,
        })
      } else {
        alert('获取权限失败' + res.err)
      }
    })
  }

  loginClick = e => {
    if (e) {
      this.setState({
        user: e.user
      })
      this.get_authority(e.user, e.random_str)
    }
  }

  render() {
    if (!this.state.user) {
      return <div><Login onClick={e => this.loginClick(e)} /></div>
    }

    return (
      <div>
        <HashRouter>
          <div className='top_title'>
            <p style={{ float: 'left', color: 'white', paddingLeft: 20, paddingBottom: 0, paddingTop: 8, marginBottom: 0 }}><Icon style={{ fontSize: 16 }} type="warning" /> 任何疑问请联系：{this.state.emergency}</p>
            <Button
              icon="poweroff"
              style={{
                height: '25px',
                marginTop: '7px',
                float: 'right',
                marginRight: '20px',
                backgroundColor: 'transparent',
                color: 'white',
                outline: 'none',
                border: 'none'
              }}
              onClick={() => {
                this.setState({ user: false })
                Cookies.remove('user');
              }}
            >{this.state.user} 退出登录</Button>
          </div>
          <div style={cssRoot} className='left_nav'><MenuLeft
            data={this.state.authority}
            menuSelect={status => this.setState({ status })}
          /></div>
          <div style={cssRoot} className='content'><div style={{ minWidth: 830 }}><RouterConfig /></div></div>
        </HashRouter>
      </div>
    )
  }
}
